import Vue from "vue";
import Vuex from "vuex";

import application from "./modules/application";
import goods from "./modules/goods";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    application, goods
  },
  
});
