<template >  
  <b-navbar fixed="top" toggleable="md" :type="sticky ? 'light' : 'dark'" :variant="sticky ? 'white' : 'primary'">
    <div class="container">
      <b-navbar-brand :to="{name: 'Landing'}">
        <img :src="logoUrl" class="d-inline-block align-top logoImg" alt="Logo">
        <span class="ml-2">{{ $t("brand.Name") }}</span>
      </b-navbar-brand>

      <!-- переключалка меню на мобилке -->
      <b-navbar-toggle target="nav-header-toggle-collapse">
        <template #default="{ expanded }">
          <b-icon-list-nested v-if="expanded" />
          <b-icon-list v-else />
        </template>
      </b-navbar-toggle>

      <!-- меню -->
      <b-collapse id="nav-header-toggle-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item :to="{name: 'Landing', hash:'#howitworks'}" class="mr-2">{{ $t("navigation.Howitworks") }}</b-nav-item>
          <b-nav-item :to="{name: 'Landing', hash:'#feedback'}" class="mr-2">{{ $t("navigation.Feedback") }}</b-nav-item>
          <b-nav-item :to="{name: 'Landing', hash:'#faq'}" class="mr-2">{{ $t("navigation.Faq") }}</b-nav-item>

          <b-nav-form>          
            <b-button :variant="!sticky ? 'light' : 'primary'" pill size="sm" class="px-3"
                href="#goods">

            {{ $t("navigation.Login") }}</b-button>
          </b-nav-form>
        </b-navbar-nav>
      </b-collapse>
    </div>
  </b-navbar> 
</template>

<script>

import { mapGetters } from "vuex";

export default {
  name: "Header",
  components: {  },  
  data() {
    return {
      sticky: false
    };
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    logoUrl() {
      let url = '/favicon-144x144_light.png'
      if (this.sticky) {
        url = '/android-icon-144x144.png'
      }
      return url ;
    },
    ...mapGetters('application', ['lang', ])
    
  },
  methods: {
    handleScroll (event) {
      if (window.scrollY>20) {
        this.sticky = true        
      }
      else{
        this.sticky = false        
      }
    },
    
  },
  
  
};
</script>

<style lang="scss" scoped>
.logoImg{
  height: 32px;
  width: 32px;
}
</style>
