<template>
  <b-navbar type="dark" variant="secondary" class="py-3">
    <div class="container footer">
         <b-row class="w-100">
        <b-col lg="6"> 
          <b-navbar-nav>
            <b-nav-item :to="{name: 'PablicOffer'}">Условия использования</b-nav-item>
            <b-nav-item :to="{name: 'PaymentsAgriment'}">Условия оплаты</b-nav-item>            
          </b-navbar-nav>
        </b-col>

        <b-col lg="3">
          <img
              src="/images/cards-logo.png"
              class="d-inline-block align-top cardsImg"
              alt="Cards"
            />
        </b-col>

        <b-col lg="3">
          <b-nav-text class="ml-auto">
            <span>©</span>
            <span class="ml-1">{{ $t("brand.Name") }}</span>
            <span class="ml-1">{{year}}</span>           
          </b-nav-text> 

          <b-navbar-brand :to="{name: 'Landing'}" class="ml-3">
            <img
              src="/favicon-144x144_light.png"
              class="d-inline-block align-top logoImg"
              alt="Logo"
            />
          </b-navbar-brand> 
        </b-col>
        </b-row>
         

    </div>
  </b-navbar>
</template>

<script>

import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "Footer",
  components: {},
  computed: {
    ...mapGetters('application', ['config', 'lang']),
    year(){
      return moment().format('YYYY')
    },    
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.footer{
  font-size: small;
}
.logoImg{
  height: 32px;
  width: 32px;
}
.cardsImg{
  height: 32px;
}
</style>
