
import i18n from "@/i18n";

export default {
    namespaced: true,
    state: {
        configs: {
            lang: i18n.locale,
            title: i18n.t("brand.meta_tags.title"),
            metaTags: {
                keywords: i18n.t("brand.meta_tags.keywords"),
                description: i18n.t("brand.meta_tags.description")
            }
        },
        preloader: {
            main: true,
            soft: false,
        },
    },
    getters: {        
        config(state, getters) {
            return state.configs
        },
        lang(state, getters) {
            return state.configs.lang
        },
        isShowPreloader: (state) => (item) => {
            return state.preloader[item]
        },
        
    },
    mutations: {
        setLang(state, data){
            if (data) {                
                state.configs.lang = data
                state.configs.title = i18n.t("brand.meta_tags.title")
                state.configs.metaTags.keywords = i18n.t("brand.meta_tags.keywords")
                state.configs.metaTags.description = i18n.t("brand.meta_tags.description")
            }            
        },
        
    },
    actions: {
                
    }
}
