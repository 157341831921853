
import MainLayout from "../../layouts/MainLayout";
const Landing = () => import("@/views/landing/Landing");
const GoodsItem = () => import("@/views/landing/goods/GoodsItem");
const PablicOffer = () => import("@/views/landing/PablicOffer");
const PaymentsAgriment = () => import("@/views/landing/PaymentsAgriment");

let defaultRoute = [
    {
        path: '/',
        component: MainLayout,
        children: [
            {
                path: '/',
                component: Landing,
                name: 'Landing', 
            },
            {
                path: '/terms',
                component: PablicOffer,
                name: 'PablicOffer', 
            },
            {
                path: '/payments',
                component: PaymentsAgriment,
                name: 'PaymentsAgriment', 
            },
            {
                path: ':lang',
                component: Landing,
                name: 'Lang_Landing', 
            },
            {
                path: '/goods/:id',
                component: GoodsItem,
                name: 'GoodsItem',                 
            },
        ]            
    },   
    
]
export default []
    .concat(defaultRoute)