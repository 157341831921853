
export default {
    namespaced: true,
    state: {
        
    },
    getters: {        
        list(state, getters) {

            let items = []
            
            items.push(
                {id: 10,
                img:'/images/servicesLogo/midjourney_st.png',
                name:'Подписка Midjorney Standart',
                description:'1 месяц',
                price: 4300,}
            )
            items.push(
                {id: 11,
                img:'/images/servicesLogo/midjourney_pro.png',
                name:'Подписка Midjorney Pro',
                description:'1 месяц',
                price: 8600,}
            )
            items.push(
                {id: 12,
                img:'/images/servicesLogo/midjourney_st.png',
                name:'Подписка Midjorney Standart',
                description:'12 месяцев',
                price: 41500,}
            )
            items.push(
                { id: 13,
                img:'/images/servicesLogo/midjourney_pro.png',
                name:'Подписка Midjorney Pro',
                description:'12 месяцев',
                price: 81900,}
            )
            
                   
            return items
        },  
        goodItem: (state, getters) => (id) => {
            let i = getters.list.find((element) => element.id == id)
            return i
        },      
    },
    mutations: {
        
        
    },
    actions: {
                
    }
}
