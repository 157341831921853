<template>
  <div class="layout">
    <Header/>    
      <router-view />
    <Footer/>   
  </div>
</template>

<script>
import Header from './Header'
import Footer from './Footer'

export default {
  name: "MainLayout",
  mounted() {},
  components: { 
    Header,
    Footer
   },
  methods: {
    
  },
  data() {
    return {
      
    };
  },
  computed: {
    
  },
};
</script>

<style scoped>
.layout {
  background: #ffffff;
  overflow: auto;
}

</style>
