import * as Sentry from "@sentry/vue";
import Vue from "vue";
import {CaptureConsole, ExtraErrorData} from "@sentry/integrations";
import {BrowserTracing} from "@sentry/tracing";
import router from "@/router";

if (process.env.NODE_ENV === 'production')
    Sentry.init({
        Vue,
        autoSessionTracking: true,
        trackComponents: true,
        environment: process.env.NODE_ENV,
        release: process.env.RELEASE,
        dsn: "https://ce8c38a6b9ae494cb4d41bd66c05de3b@o1214922.ingest.sentry.io/6355899",
        integrations: [
            new Sentry.Integrations.TryCatch({
                eventTarget: false
            }),
            new CaptureConsole({
                levels: ['error', 'warn'],
            }),
            new ExtraErrorData({depth: 20}),
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: ["m.lunchpad.ru"],
            }),
        ],
        tracesSampleRate: 1.0,
    });